import _ from 'lodash'
import qs from 'query-string'
import { LOCALES, LANGUAGES } from '@gymondo/frontend-core/enums'

const { isDevelopmentEnv } = require('../util/env.util')

/**
 * This character accepts anything as a view
 */
const WILD_CARD = '*'

export const languages = {
    de: {
        path: LANGUAGES.GERMAN,
        localeName: 'Deutsch',
        locale: LOCALES.DE_DE,
    },
    en: {
        path: LANGUAGES.ENGLISH,
        localeName: 'English',
        locale: LOCALES.EN_US,
    },
}

export const ROUTES_ID = {
    HOME: 'home',
    HOW_IT_WORKS: 'how-it-works',
    CHECKOUT: 'checkout',
    CHECKOUT_PACKAGE: 'checkout-package',
    PUBLIC_PAGES_CHECKOUT_PACKAGE: 'public-pages-checkout-package',
    PUBLIC_PAGES_PLUS: 'public-pages-plus',
    PUBLIC_PAGES_TRANSFORMATION_CHALLENGE:
        'public-pages-transformation-challenge',
    PUBLIC_PAGES_TRAINING: 'public-pages-training',
    CHECKOUT_SIGNUP: 'checkout-signup',
    IMPRINT: 'imprint',
    CHECKOUT_SUBSCRIBE: 'checkout-subscribe',
    PRIVACY: 'privacy',
    COOKIE_POLICY: 'cookie-policy',
    PROGRAM: 'programs',
    TERMS_AND_CONDITIONS: 'term-and-condition',
    TV_SIGNUP: 'tvsignup',
}

/**
 * This object sets the paths to access the pages
 * The keys are unique ids that will be used on the LocalizedLinks and
 *  also to identify the pages
 * @param {string} path: URL path
 * @param {object} customComponents: the path of the folder for the specific component
 * @param {object} views: variations of the page template
 * @param {Array} ignore: contains languages that should be skipped by page generation
 * @param {boolean} template: if the page is a template, it's generated by script,
 *  but url translation is kept here
 * @param {boolean} content: marks pages generated by script with content in md format,
 * @param {string} idParent: the page will use the content and the styling of the parent
 * @param {boolean} isExternal: if the path is a localized external path
 */
export const urls = {
    404: {
        path: '/404.html',
        template: true,
    },
    [ROUTES_ID.CHECKOUT]: {
        path: '/checkout/',
        pageContext: { noHeader: true },
    },
    [ROUTES_ID.CHECKOUT_PACKAGE]: {
        path: '/checkout/package/',
        idParent: ROUTES_ID.CHECKOUT,
        pageContext: { noHeader: true },
    },
    [ROUTES_ID.CHECKOUT_SIGNUP]: {
        path: '/checkout/signup/',
        pageContext: {
            footerType: 'SIMPLE',
        },
    },
    [ROUTES_ID.CHECKOUT_SUBSCRIBE]: {
        path: '/checkout/subscribe/',
        idParent: ROUTES_ID.CHECKOUT_SIGNUP,
    },
    [ROUTES_ID.PUBLIC_PAGES_CHECKOUT_PACKAGE]: {
        path: '/packages',
        isExternal: true,
        devServerPort: 8080,
    },
    [ROUTES_ID.PUBLIC_PAGES_PLUS]: {
        path: '/gymondo-plus',
        isExternal: true,
        devServerPort: 8080,
    },
    [ROUTES_ID.PUBLIC_PAGES_TRANSFORMATION_CHALLENGE]: {
        path: '/transformation-challenge',
        isExternal: true,
        devServerPort: 8080,
    },
    [ROUTES_ID.PUBLIC_PAGES_TRAINING]: {
        path: '/training',
        isExternal: true,
        devServerPort: 8080,
    },
    [ROUTES_ID.TV_SIGNUP]: {
        path: '/tvsignup/',
        pageContext: {
            footerType: 'HIDDEN',
            noHeader: true,
        },
    },
    community: {
        path: '/community/',
        views: {
            facebook: { path: 'facebook' },
            instagram: { path: 'instagram' },
        },
    },
    coop: {
        path: '/coop/signup/',
        pageContext: {
            footerType: 'SIMPLE',
        },
        views: {
            'dak-mitarbeiter': { path: 'dak-mitarbeiter' },
            dak: { path: 'dak' },
            'mhplus-mitarbeiter': { path: 'mhplus-mitarbeiter' },
            mhplus: { path: 'mhplus' },
            klosterfrau: { path: 'klosterfrau' },
            partner: { path: 'partner' },
        },
    },
    [ROUTES_ID.COOKIE_POLICY]: {
        path: '/cookie-policy/',
        template: true,
    },
    [ROUTES_ID.HOME]: { path: '/' },
    [ROUTES_ID.IMPRINT]: {
        path: '/imprint/',
        content: true,
    },
    'imprint-app': {
        path: '/imprint/app/',
        content: true,
        idParent: 'imprint',
        pageContext: { noHeader: true },
    },
    newsletter: {
        path: '/newsletter/',
        views: {
            unsubscribe: { path: 'unsubscribe' },
        },
    },
    partner: {
        path: '/partner/',
        pageContext: {
            footerType: 'SIMPLE',
        },
        views: {
            freenet: { path: 'freenet', ignore: [LANGUAGES.ENGLISH] },
            ojom: { path: 'ojom', ignore: [LANGUAGES.ENGLISH] },
            klarmobil: { path: 'klarmobil', ignore: [LANGUAGES.ENGLISH] },
            webde: { path: 'webde', ignore: [LANGUAGES.ENGLISH] },
            o2: { path: 'o2', ignore: [LANGUAGES.ENGLISH] },
            gmx: { path: 'gmx', ignore: [LANGUAGES.ENGLISH] },
            vattenfall: { path: 'vattenfall', ignore: [LANGUAGES.ENGLISH] },
            vodafone: { path: 'vodafone', ignore: [LANGUAGES.ENGLISH] },
            a1: { path: 'a1', ignore: [LANGUAGES.ENGLISH] },
            du: { path: 'du', ignore: [LANGUAGES.GERMAN] },
            vodacomZa: { path: 'vodacom-za', ignore: [LANGUAGES.GERMAN] },
            zain: { path: 'zain', ignore: [LANGUAGES.GERMAN] },
            kpn: { path: 'kpn', ignore: [LANGUAGES.GERMAN] },
            orangeRo: { path: 'orange-ro', ignore: [LANGUAGES.GERMAN] },
        },
    },
    press: { path: '/press/' },
    [ROUTES_ID.PROGRAM]: {
        path: '/programs/',
        template: true,
        views: WILD_CARD,
    },
    [ROUTES_ID.HOW_IT_WORKS]: { path: '/instructions/' },
    referral: { path: '/referral/' },
    [ROUTES_ID.PRIVACY]: {
        path: '/privacy/',
        template: true,
        views: {
            mhplus: {
                path: 'mhplus',
                ignore: [LANGUAGES.ENGLISH],
            },
        },
    },
    success: { path: '/success/' },
    [ROUTES_ID.TERMS_AND_CONDITIONS]: {
        path: '/terms-and-conditions/',
        template: true,
        views: {
            freenet: { path: 'freenet', ignore: [LANGUAGES.ENGLISH] },
            ojom: { path: 'ojom', ignore: [LANGUAGES.ENGLISH] },
            klarmobil: { path: 'klarmobil', ignore: [LANGUAGES.ENGLISH] },
            webde: { path: 'webde', ignore: [LANGUAGES.ENGLISH] },
            o2: { path: 'o2', ignore: [LANGUAGES.ENGLISH] },
            gmx: { path: 'gmx', ignore: [LANGUAGES.ENGLISH] },
            vattenfall: { path: 'vattenfall', ignore: [LANGUAGES.ENGLISH] },
            vodafone: { path: 'vodafone', ignore: [LANGUAGES.ENGLISH] },
            a1: { path: 'a1', ignore: [LANGUAGES.ENGLISH] },
            du: { path: 'du', ignore: [LANGUAGES.GERMAN] },
            vodacomZa: { path: 'vodacom-za', ignore: [LANGUAGES.GERMAN] },
            zain: { path: 'zain', ignore: [LANGUAGES.GERMAN] },
            kpn: { path: 'kpn', ignore: [LANGUAGES.GERMAN] },
            orangeRo: { path: 'orange-ro', ignore: [LANGUAGES.GERMAN] },
        },
    },
    /**
     * This route is used outside the app for redirections
     */
    rd: {
        path: 'rd',
        template: true,
    },
}

/**
 * Check if the ignore array of the object contains language slug
 * @param {object} obj url or view
 * @param {string} lang language slug ('en' || 'de')
 */
export const ignoreLanguage = (obj, lang) =>
    obj && obj.ignore && obj.ignore.includes(lang)

export const getCurrentHost = () =>
    typeof window !== 'undefined' &&
    window.location &&
    `${window.location.protocol}//${window.location.host}`

/**
 * In development, send user to dev server
 */
const getDevHostname = (devServerPort = 3000) =>
    (isDevelopmentEnv &&
        typeof window !== 'undefined' &&
        window.location &&
        `${window.location.protocol}//${window.location.hostname}:${devServerPort}`) ||
    ''

/**
 * Get path for an URL
 * @param {string} id Key of the url item from the urls
 * @param {string} lang Language of the path
 * @param {string} viewId id of the view
 * @param {object} query query parameters of the url
 * @param {string} anchor anchor of the url
 */
export const getPathUrl = ({
    id,
    lang = LANGUAGES.GERMAN,
    viewId,
    query,
    anchor,
    fallback = true,
}) => {
    const url = urls[id]
    const path = url && url.path

    if (
        !path ||
        ignoreLanguage(url, lang) ||
        ignoreLanguage(_.get(url, `views.${viewId}`), lang)
    ) {
        return fallback ? `/${lang}/` : null
    }

    /**
     * Localized path
     */
    const localizedPath = `/${lang}${url.path}`

    /**
     * Get view localized
     * if url.views is a wild card (*) allow any view,
     * if url.views is an object, view must a key of it
     */
    let viewPath
    if (url.views === WILD_CARD) {
        viewPath = viewId
    } else if (!ignoreLanguage(_.get(url, `views.${viewId}`), lang)) {
        viewPath = _.get(url, `views.${viewId}.path`)
    }

    /**
     * Get query
     */
    const queryPath = query && qs.stringify(query)

    /**
     * Return URL formed: localizedPath + viewPath + ? + query + anchor
     * E.g. '/de/partner/' + 'o2' + ? + 'ga=123' + '#section-2'
     */
    return [
        url.isExternal &&
            (getDevHostname(url.devServerPort) || getCurrentHost()),
        localizedPath,
        viewPath && `${viewPath}/`,
        queryPath && `?${queryPath}`,
        anchor,
    ]
        .filter((it) => it)
        .join('')
}

/* Get all possible urls for page */
export const getAllPageUrls = (page, view, views) =>
    Object.keys(languages)
        .map((lang) => {
            const viewDynamic = views && views[lang]
            if (views && !viewDynamic) {
                return null
            }

            const url = getPathUrl({
                lang,
                id: page,
                viewId: viewDynamic || view,
                fallback: false,
            })

            if (!url) {
                return null
            }

            return {
                lang,
                url,
            }
        })
        .filter((l) => l)

/**
 * A map with paths that don't go trough gatsby router
 */
export const PATHS = {
    home: '/',
    secure: `${getDevHostname()}/secure`,
    newsletter: `${getDevHostname()}/train/account/preferences`,
    account: `${getDevHostname()}/train/account/subscription`,
    onboarding: `${getDevHostname()}/train/onboarding`,
    timeline: `${getDevHostname()}/train/timeline`,
    deviceAuth: `${getDevHostname()}/train/account/device-auth`,
}
